/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./notification.component";
import * as i9 from "../../shared/window";
import * as i10 from "../../shared/current-date";
var styles_NotificationComponent = [];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: { "animation": [{ type: 7, name: "hideAnimation", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { height: 0 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: null, timings: 250 }, options: null }], options: {} }] } });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
export function View_NotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contentClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close-button"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(3, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "mat-icon", [["aria-label", "Dismiss notification"], ["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i0.ɵdid(5, 638976, null, 0, i7.MatIcon, [i0.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "close"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).disabled || null); var currVal_1 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 5).inline; _ck(_v, 4, 0, currVal_2); }); }
export function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-notification", [], [[40, "@hideAnimation", 0]], null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 114688, null, 0, i8.NotificationComponent, [i9.WindowToken, i10.CurrentDateToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).showNotification; _ck(_v, 0, 0, currVal_0); }); }
var NotificationComponentNgFactory = i0.ɵccf("aio-notification", i8.NotificationComponent, View_NotificationComponent_Host_0, { dismissOnContentClick: "dismissOnContentClick", notificationId: "notificationId", expirationDate: "expirationDate" }, { dismissed: "dismissed" }, ["*"]);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
