import { ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, of, timer } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FILE_NOT_FOUND_ID, FETCHING_ERROR_ID } from 'app/documents/document.service';
import { Logger } from 'app/shared/logger.service';
import { TocService } from 'app/shared/toc.service';
import { ElementsLoader } from 'app/custom-elements/elements-loader';
// Constants
export var NO_ANIMATIONS = 'no-animations';
// Initialization prevents flicker once pre-rendering is on
var initialDocViewerElement = document.querySelector('aio-doc-viewer');
var initialDocViewerContent = initialDocViewerElement ? initialDocViewerElement.innerHTML : '';
var DocViewerComponent = /** @class */ (function () {
    function DocViewerComponent(elementRef, logger, titleService, metaService, tocService, elementsLoader) {
        var _this = this;
        this.logger = logger;
        this.titleService = titleService;
        this.metaService = metaService;
        this.tocService = tocService;
        this.elementsLoader = elementsLoader;
        this.void$ = of(undefined);
        this.onDestroy$ = new EventEmitter();
        this.docContents$ = new EventEmitter();
        this.currViewContainer = document.createElement('div');
        this.nextViewContainer = document.createElement('div');
        // The new document is ready to be inserted into the viewer.
        // (Embedded components have been loaded and instantiated, if necessary.)
        this.docReady = new EventEmitter();
        // The previous document has been removed from the viewer.
        // (The leaving animation (if any) has been completed and the node has been removed from the DOM.)
        this.docRemoved = new EventEmitter();
        // The new document has been inserted into the viewer.
        // (The node has been inserted into the DOM, but the entering animation may still be in progress.)
        this.docInserted = new EventEmitter();
        // The new document has been fully rendered into the viewer.
        // (The entering animation has been completed.)
        this.docRendered = new EventEmitter();
        this.hostElement = elementRef.nativeElement;
        // Security: the initialDocViewerContent comes from the prerendered DOM and is considered to be secure
        this.hostElement.innerHTML = initialDocViewerContent;
        if (this.hostElement.firstElementChild) {
            this.currViewContainer = this.hostElement.firstElementChild;
        }
        this.docContents$
            .pipe(switchMap(function (newDoc) { return _this.render(newDoc); }), takeUntil(this.onDestroy$))
            .subscribe();
    }
    Object.defineProperty(DocViewerComponent.prototype, "doc", {
        set: function (newDoc) {
            // Ignore `undefined` values that could happen if the host component
            // does not initially specify a value for the `doc` input.
            if (newDoc) {
                this.docContents$.emit(newDoc);
            }
        },
        enumerable: true,
        configurable: true
    });
    DocViewerComponent.prototype.ngOnDestroy = function () {
        this.onDestroy$.emit();
    };
    /**
     * Prepare for setting the window title and ToC.
     * Return a function to actually set them.
     */
    DocViewerComponent.prototype.prepareTitleAndToc = function (targetElem, docId) {
        var _this = this;
        var titleEl = targetElem.querySelector('h1');
        var needsToc = !!titleEl && !/no-?toc/i.test(titleEl.className);
        var embeddedToc = targetElem.querySelector('aio-toc.embedded');
        if (needsToc && !embeddedToc) {
            // Add an embedded ToC if it's needed and there isn't one in the content already.
            titleEl.insertAdjacentHTML('afterend', '<aio-toc class="embedded"></aio-toc>');
        }
        else if (!needsToc && embeddedToc) {
            // Remove the embedded Toc if it's there and not needed.
            embeddedToc.remove();
        }
        return function () {
            _this.tocService.reset();
            var title = '';
            // Only create ToC for docs with an `<h1>` heading.
            // If you don't want a ToC, add "no-toc" class to `<h1>`.
            if (titleEl) {
                title = (typeof titleEl.innerText === 'string') ? titleEl.innerText : titleEl.textContent;
                if (needsToc) {
                    _this.tocService.genToc(targetElem, docId);
                }
            }
            _this.titleService.setTitle(title ? "Angular - " + title : 'Angular');
        };
    };
    /**
     * Add doc content to host element and build it out with embedded components.
     */
    DocViewerComponent.prototype.render = function (doc) {
        var _this = this;
        var addTitleAndToc;
        this.setNoIndex(doc.id === FILE_NOT_FOUND_ID || doc.id === FETCHING_ERROR_ID);
        return this.void$.pipe(
        // Security: `doc.contents` is always authored by the documentation team
        //           and is considered to be safe.
        tap(function () { return _this.nextViewContainer.innerHTML = doc.contents || ''; }), tap(function () { return addTitleAndToc = _this.prepareTitleAndToc(_this.nextViewContainer, doc.id); }), switchMap(function () { return _this.elementsLoader.loadContainedCustomElements(_this.nextViewContainer); }), tap(function () { return _this.docReady.emit(); }), switchMap(function () { return _this.swapViews(addTitleAndToc); }), tap(function () { return _this.docRendered.emit(); }), catchError(function (err) {
            var errorMessage = (err instanceof Error) ? err.stack : err;
            _this.logger.error(new Error("[DocViewer] Error preparing document '" + doc.id + "': " + errorMessage));
            _this.nextViewContainer.innerHTML = '';
            _this.setNoIndex(true);
            return _this.void$;
        }));
    };
    /**
     * Tell search engine crawlers whether to index this page
     */
    DocViewerComponent.prototype.setNoIndex = function (val) {
        if (val) {
            this.metaService.addTag({ name: 'robots', content: 'noindex' });
        }
        else {
            this.metaService.removeTag('name="robots"');
        }
    };
    /**
     * Swap the views, removing `currViewContainer` and inserting `nextViewContainer`.
     * (At this point all content should be ready, including having loaded and instantiated embedded
     *  components.)
     *
     * Optionally, run a callback as soon as `nextViewContainer` has been inserted, but before the
     * entering animation has been completed. This is useful for work that needs to be done as soon as
     * the element has been attached to the DOM.
     */
    DocViewerComponent.prototype.swapViews = function (onInsertedCb) {
        var _this = this;
        if (onInsertedCb === void 0) { onInsertedCb = function () { }; }
        var raf$ = new Observable(function (subscriber) {
            var rafId = requestAnimationFrame(function () {
                subscriber.next();
                subscriber.complete();
            });
            return function () { return cancelAnimationFrame(rafId); };
        });
        // Get the actual transition duration (taking global styles into account).
        // According to the [CSSOM spec](https://drafts.csswg.org/cssom/#serializing-css-values),
        // `time` values should be returned in seconds.
        var getActualDuration = function (elem) {
            var cssValue = getComputedStyle(elem).transitionDuration || '';
            var seconds = Number(cssValue.replace(/s$/, ''));
            return 1000 * seconds;
        };
        var animateProp = function (elem, prop, from, to, duration) {
            if (duration === void 0) { duration = 200; }
            var animationsDisabled = !DocViewerComponent.animationsEnabled
                || _this.hostElement.classList.contains(NO_ANIMATIONS);
            if (prop === 'length' || prop === 'parentRule') {
                // We cannot animate length or parentRule properties because they are readonly
                return _this.void$;
            }
            elem.style.transition = '';
            return animationsDisabled
                ? _this.void$.pipe(tap(function () { return elem.style[prop] = to; }))
                : _this.void$.pipe(
                // In order to ensure that the `from` value will be applied immediately (i.e.
                // without transition) and that the `to` value will be affected by the
                // `transition` style, we need to ensure an animation frame has passed between
                // setting each style.
                switchMap(function () { return raf$; }), tap(function () { return elem.style[prop] = from; }), switchMap(function () { return raf$; }), tap(function () { return elem.style.transition = "all " + duration + "ms ease-in-out"; }), switchMap(function () { return raf$; }), tap(function () { return elem.style[prop] = to; }), switchMap(function () { return timer(getActualDuration(elem)); }), switchMap(function () { return _this.void$; }));
        };
        var animateLeave = function (elem) { return animateProp(elem, 'opacity', '1', '0.1'); };
        var animateEnter = function (elem) { return animateProp(elem, 'opacity', '0.1', '1'); };
        var done$ = this.void$;
        if (this.currViewContainer.parentElement) {
            done$ = done$.pipe(
            // Remove the current view from the viewer.
            switchMap(function () { return animateLeave(_this.currViewContainer); }), tap(function () { return _this.currViewContainer.parentElement.removeChild(_this.currViewContainer); }), tap(function () { return _this.docRemoved.emit(); }));
        }
        return done$.pipe(
        // Insert the next view into the viewer.
        tap(function () { return _this.hostElement.appendChild(_this.nextViewContainer); }), tap(function () { return onInsertedCb(); }), tap(function () { return _this.docInserted.emit(); }), switchMap(function () { return animateEnter(_this.nextViewContainer); }), 
        // Update the view references and clean up unused nodes.
        tap(function () {
            var prevViewContainer = _this.currViewContainer;
            _this.currViewContainer = _this.nextViewContainer;
            _this.nextViewContainer = prevViewContainer;
            _this.nextViewContainer.innerHTML = ''; // Empty to release memory.
        }));
    };
    // Enable/Disable view transition animations.
    DocViewerComponent.animationsEnabled = true;
    return DocViewerComponent;
}());
export { DocViewerComponent };
